* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

body.filter-drawer-open {
  overflow: hidden;
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}